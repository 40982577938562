<template>
  <div v-if="showMergeModal" class="merge-modal-wrapper">
    <div class="merge-modal-container">
      <div class="merger">
        <v-col class="merger-search-bar" cols="12" sm="8" md="6">
          <v-autocomplete
            dense
            outlined
            v-model="localDataForMoveTo"
            :loading="isLoading"
            :search-input.sync="search"
            @change="handleSearchInput"
            hide-no-data
            clearable
            :items="filteredListForMergerSearchBar"
            :item-text="type == 'address' ? 'branch_name' : 'name'"
            :placeholder="`Search ${type}`"
            return-object
          >
          </v-autocomplete>
        </v-col>
        <div class="merger-main-content">
          <div class="merger-section left-section">
            <div v-for="key in keysToCompareForMerging" :key="key.key">
              <div v-if="dataForMoveFrom[key.key]">
                <div class="fields">
                  <div>
                    <div>{{ "\xa0" }}</div>
                    <div v-if="dataForMoveTo">
                      <div v-if="Array.isArray(dataForMoveFrom[key.key])">
                        <v-checkbox
                          v-model="mergedData[key.key]"
                          :value="dataForMoveFrom[key.key].join(', ')"
                          :ripple="false"
                          hide-details
                        ></v-checkbox>
                      </div>
                      <div v-else>
                        <v-radio-group
                          v-model="mergedData[key.key]"
                          :ripple="false"
                          hide-details
                        >
                          <v-radio :value="dataForMoveFrom[key.key]"></v-radio>
                        </v-radio-group>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="fields-heading">
                      {{ key.text }}
                    </div>
                    <div>
                      <div v-if="Array.isArray(dataForMoveFrom[key.key])">
                        {{ dataForMoveFrom[key.key].join(", ") }}
                      </div>
                      <div v-else>
                        {{ dataForMoveFrom[key.key] }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="merger-section right-section">
            <template v-if="dataForMoveTo">
              <div v-for="key in keysToCompareForMerging" :key="key.key">
                <div v-if="dataForMoveTo[key.key]">
                  <div class="fields">
                    <div>
                      <div>{{ "\xa0" }}</div>
                      <div>
                        <div v-if="Array.isArray(dataForMoveTo[key.key])">
                          <v-checkbox
                            v-if="dataForMoveTo"
                            v-model="mergedData[key.key]"
                            :value="dataForMoveTo[key.key].join(', ')"
                            :ripple="false"
                            hide-details
                          ></v-checkbox>
                        </div>
                        <div v-else>
                          <v-radio-group
                            v-model="mergedData[key.key]"
                            :ripple="false"
                            hide-details
                          >
                            <v-radio :value="dataForMoveTo[key.key]"></v-radio>
                          </v-radio-group>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="fields-heading">
                        {{ key.text }}
                      </div>
                      <div>
                        <div v-if="Array.isArray(dataForMoveTo[key.key])">
                          {{ dataForMoveTo[key.key].join(", ") }}
                        </div>
                        <div v-else>
                          {{ dataForMoveTo[key.key] }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div v-else>
              please search first for a company
            </div>
          </div>
        </div>
        <div class="merger-merge-btn-wrapper">
          <v-btn depressed text large color="success" @click="handleMergeClick">
            Merge
          </v-btn>
        </div>
      </div>
      <v-btn class="close-merger-btn" depressed plain @click="closeMergeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "MergeModal",
  data: () => ({
    localDataForMoveTo: {},
    mergedData: {},
    isLoading: false,
    search: "",
    searchList: [],
  }),
  computed: {
    ...mapGetters("ManageAgents", [
      "showMergeModal",
      "dataForMoveFrom",
      "dataForMoveTo",
    ]),
    filteredListForMergerSearchBar() {
      if (this.dataForMoveFrom) {
        return this.searchList.filter(
          (company) => company._id != this.dataForMoveFrom._id
        );
      }
      return this.searchList;
    },
  },
  methods: {
    ...mapMutations("ManageAgents", ["closeMergeModal", "setDataForMoveTo"]),
    ...mapActions("ManageAgents", [
      "mergeTravelAgents",
      "getCompaniesList",
      "getAddressList",
      "getCompanyEmployeeList",
    ]),
    saveMergedData(key, data) {
      let val = JSON.parse(JSON.stringify(data));
      if (Array.isArray(val)) {
        if (this.mergedData[key]) {
          this.mergedData[key].push(...val);
          return;
        }
      }
      this.mergedData[key] = val;
    },
    handleSearchInput() {
      if (!this.localDataForMoveTo) return;
      this.setDataForMoveTo(this.localDataForMoveTo);
      this.keysToCompareForMerging.forEach((obj) => {
        if (Array.isArray(this.dataForMoveTo[obj.key])) {
          this.$set(this.mergedData, obj.key, [
            this.dataForMoveTo[obj.key].join(", "),
          ]);
        } else {
          this.$set(this.mergedData, obj.key, this.dataForMoveTo[obj.key]);
        }
        // this.mergedData[obj.key] = this.dataForMoveTo[obj.key];
      });
    },
    handleMergeClick() {
      let payload = JSON.parse(JSON.stringify(this.mergedData));
      for (let [key, value] of Object.entries(payload)) {
        if (Array.isArray(value)) {
          let mergedArray = [];
          payload[key].forEach((ele) => {
            mergedArray.push(...ele.split(", "));
          });
          payload[key] = [...new Set(mergedArray)];
        }
      }
      if (
        confirm("Mergeing is irreversible, are you sure you want to continue?")
      ) {
        this.mergeTravelAgents({
          moveFromId: this.dataForMoveFrom._id,
          moveToId: this.dataForMoveTo._id,
          mergedData: payload,
          type: this.type,
        }).then((response) => {
          if (response.ok) {
            this.$emit("onSuccessfullMerge");
            this.closeMergeModal();
          }
        });
      }
    },
    apiCallForSearchBar(payload) {
      switch (this.type) {
        case "company":
          this.getCompaniesList(payload)
            .then((res) => {
              const { list } = res;
              if (res.ok) {
                this.searchList = list;
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => (this.isLoading = false));
          break;
        case "address":
          payload.filter.company_id = this.dataForMoveFrom.company_id;
          this.getAddressList({
            ...payload,
            company_id: this.dataForMoveFrom.company_id,
          })
            .then((res) => {
              const { list } = res;
              if (res.ok) {
                this.searchList = list;
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => (this.isLoading = false));
          payload.company_id = this.dataForMoveFrom.company_id;
          break;
        case "employee":
          payload.filter.company_id = this.dataForMoveFrom.company_id;
          this.getCompanyEmployeeList({
            ...payload,
            company_id: this.dataForMoveFrom.company_id,
          })
            .then((res) => {
              const { list } = res;
              if (res.ok) {
                this.searchList = list;
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => (this.isLoading = false));
          payload.company_id = this.dataForMoveFrom.company_id;
          break;
      }
    },
  },
  props: {
    keysToCompareForMerging: {
      required: true,
      type: Array,
      default: () => {
        return [];
      },
    },
    type: {
      required: true,
      required: true,
      default: "",
    },
  },
  watch: {
    search(val) {
      if (this.isLoading) return;
      this.isLoading = true;
      let payload = {
        filter: {},
        pageSize: 100,
        pageNo: 1,
      };
      if (val && val.length > 0) {
        payload.filter.search_text = val;
      }
      this.apiCallForSearchBar(payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.merge-modal-wrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}
.merge-modal-container {
  max-height: 70%;
  max-width: 50%;
  min-width: 50%;
  position: relative;
  background-color: white;
  overflow: auto;
}
.merger {
  width: 100%;
  &-search-bar {
    margin: 20px auto;
  }
  &-merge-btn-wrapper {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-main-content {
    height: 100%;
    display: flex;
    padding: 0 3%;
    overflow: scroll;
  }
  &-section {
    height: 100%;
    width: 50%;
  }
}
.merger-field {
  margin: 10px 0;
  font-weight: 600;
  font-size: 20px;
  &-info {
    font-weight: 400;
    font-size: 16px;
    margin-left: 24px;
  }
}
.close-merger-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  height: 50px;
  width: 50px;
}
.fields {
  display: flex;
  height: 100%;
  width: 100%;
  margin: 1rem 0;
  &-heading {
    font-size: 1.125rem;
    font-weight: 600;
  }
  div {
    width: 100%;
    height: 100%;
    &:nth-of-type(1) {
      flex-basis: 2rem;
    }
    .v-input {
      margin: 0;
      padding: 0;
    }
  }
}
</style>
